import React from "react";
import { Link } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import BackgroundImage from "../components/background-image";
import homeImage from "../images/home.jpg";

import servicesStyles from "../styles/services.module.scss";

const ServiceItem = text => (
    <p><span role="img" className={servicesStyles.check} aria-label="checkmark">✅</span>{text}</p>
)

const ServicesPage = () => (
    <Layout>
        <SEO title="Services" />
        <BackgroundImage
            color="rgba(0, 178, 255, 0.69)"
            image={homeImage}
            backgroundPosition="50% 60%"
            title="Services"
          />
          <div className={servicesStyles.whiteBackground}>
              <div className="container">
                  <h1>What we do for you</h1>
                  <div className={servicesStyles.taxSection}>
                      <h3>Tax Services</h3>
                      <div className={servicesStyles.servicesList}>
                        {ServiceItem("Tax preparation")}
                        {ServiceItem("Tax planning")}
                        {ServiceItem("Non-filed returns")}
                        {ServiceItem("Back taxes owed")}
                        {ServiceItem("Payroll problems")}
                        {ServiceItem("Backruptcy")}
                        {ServiceItem("IRS payment plan")}
                        {ServiceItem("IRS audit representation")}
                        {ServiceItem("Offer in compromise")}
                      </div>
                  </div>
                  <div className={servicesStyles.servicesGrid}>
                      <div className={servicesStyles.servicesColumn}>
                        <h3>Individuals</h3>
                        {ServiceItem("Financial planning")}
                        {ServiceItem("Estate planning")}
                        {ServiceItem("Elder care")}
                      </div>
                      <div className={servicesStyles.servicesColumn}>
                        <h3>Businesses</h3>
                        {ServiceItem("Accounting")}
                        {ServiceItem("Payroll")}
                        {ServiceItem("Audits")}
                        {ServiceItem("Business valuation")}
                      </div>
                      <div className={servicesStyles.servicesColumn}>
                        <h3>QuickBooks</h3>
                        {ServiceItem("Setup")}
                        {ServiceItem("Training")}
                        {ServiceItem("Dedicated hosting")}
                        {ServiceItem("Server management")}
                      </div>
                  </div>
              </div>
          </div>
          <div className="container">
            <hr />
          </div>
          <div className={servicesStyles.flexBackground}>
              <h1>Don't see what you're looking for?</h1>
              <h3>Not to worry! Our team is here to help!</h3>
              <Link to="/contact#form">Let's work together!</Link>
          </div>
    </Layout>

)

export default ServicesPage